import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import {Breakpoints} from './Breakpoints'

const ContainerWrapper = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin: 0 auto;

  @media (min-width: ${Breakpoints.sm}) {
    max-width: 100%;
  }
  @media (min-width: ${Breakpoints.md}) {
    max-width: ${(props) => (props.fluidMD ? '100%' : '720px')};
  }
  @media (min-width: ${Breakpoints.lg}) {
    max-width: ${(props) => (props.fluidLG ? '100%' : '960px')};
  }
  @media (min-width: ${Breakpoints.xl}) {
    max-width: ${(props) => (props.fluidXL ? '100%' : '1170px')};
  }
  @media (min-width: ${Breakpoints.xxl}) {
    max-width: ${(props) => (props.fluidXXL ? '100%' : '1400px')};
  }

  ${(props) =>
  props.fluid
  && css`
    max-width: 100%;
  `}
`

const Container = ({ children, ...props}) => (
  <ContainerWrapper {...props}>{children}</ContainerWrapper>
)

Container.propTypes = {
  children: PropTypes.node
}
Container.defaultProps = {
  children: null
}
export default Container
