import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

const RowWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '0')} !important;
  margin-left: -15px;
  margin-right: -15px;
  ${(props) =>
  props.Vcenter
  && css({
    'align-items': 'center'
  })}
`

const Row = ({ children, ...props }) => <RowWrapper {...props}>{children}</RowWrapper>

Row.propTypes = {
  children: PropTypes.node
}
Row.defaultProps = {
  children: null
}
export default Row

