import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import { Container, Section } from '../../utils/grid-system'
import theme from '../../theme/themeStyles'
import Footer from '../Footer'
import '../../styles/index.scss'
// import layoutStyles from './layout.module.scss'
import logo from '../../assets/images/helvia-circle.png'
// import NavigationData from '../../assets/content/navigation'

const Layout = ({ children }) => (
  <ThemeProvider theme={theme}>
    {/* <div className={layoutStyles.container}> */}
    {/*   <div className={layoutStyles.content}> */}
    <Helmet>
      <html lang="pt" />
      <title>Helvia Technologies | Experience Management with Bots</title>
      <link rel="shortcut icon" type="image/x-icon" href={logo} />
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap"
        rel="stylesheet"
      />
    </Helmet>
    {children}
    <Container>
      <Section>
        <Footer />
      </Section>
    </Container>
  </ThemeProvider>
)

Layout.propTypes = {
  children: PropTypes.node
}
Layout.defaultProps = {
  children: null
}

export default Layout
