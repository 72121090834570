const theme = {
  black: '#003734', // helvia
  blackLight1: '#354063', // helvia
  blackLight2: '#18191F', // helvia
  blackLight3: '#324148', // helvia
  white: '#ffffff', // helvia
  green: '#c3ffe6', // helvia
  greenLight1: '#61cdc7', // helvia
  greenLight2: '#ebfff3', // helvia
  greenLight3: '#68f8a2', // helvia
  gray: '#f7f7f7', // helvia
  blue: '#2FA9FF', // helvia
  blueLight1: '#6CABE5', // helvia
  blueLight2: '#B9E2FF', // helvia
  blueLight3: '#4994bd', // helvia
  brownLight1: '#ffa800', // helvia
  brownLight2: '#fff2d9', // helvia
  brownLight3: '#f9ce76', // helvia
  brownLight4: '#dda024', // helvia
  primaryColor: '#8A57DE',
  primaryLightColor: '#EEE6FA',
  primaryLightColorTwo: '#f8f5fd',
  secondaryColor: '#FB7B81',
  secondaryLightColor: '#FFEBEC',
  tetriaryColor: '#E281CE',
  headingColor: '#2C0075',
  textColor: '#7A848E',
  shadowColor: 'rgba(0, 0, 0, 0.05)',
  shadowLightColor: '#E6E6E6',
  menuColor: '#003734', // helvia
  menuHoverColor: '#61cdc7'
}
export default theme
