import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const SectionWrapper = styled.section`
  max-width: 1400px;
  padding-top: ${(props) => (props.paddingTop ? props.paddingTop : '40px')} !important;
  padding-bottom: ${(props) => (props.paddingBottom ? props.paddingBottom : '40px')} !important;
`

const Section = ({ children, ...props }) => <SectionWrapper {...props}>{children}</SectionWrapper>

Section.propTypes = {
  children: PropTypes.node
}
Section.defaultProps = {
  children: null
}
export default Section
