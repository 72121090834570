import React from 'react'
import styled from 'styled-components'
import Logo from '../../assets/images/Logo.svg'
import OKImage from '../../assets/images/ok@2x.png'
import { Breakpoints } from '../../utils/grid-system/Breakpoints'

// ο,τι να ναι

const BorderLine = styled.div`
  display: block;
  height: 1px;
  background-color: #F7F7F7;
`
const FooterBottom = styled.div`
  display: block;
  height: 1px;
  background-color: #F7F7F7;
  
  .top-row {
    padding: 30px 0;

    p {
      text-align: left;
    }

    @media (min-width: ${Breakpoints.md}) {
      display: flex;
      justify-content: space-between;

      p {
        text-align: right;
      }
    }
  }
  .footer-img {
    padding: 40px 0;
  }
  .ok-img {
    width: 160px;
    display: block;
    margin: 0 auto;
  }
`

//   <FooterUpperWrapper {...props}>
// {FooterData && FooterData.length > 0
//   ? FooterData.map((section) => (
//     <ul className="section-list" key={section.category.name}>
//       <div className={`${expanded ? 'is-expanded' : ''}`}>
//         <li>{section.category.name}</li>
//         <button
//           className="expandable"
//           type="button"
//           onClick={() => setExpanded(!expanded)}
//         >
//           <img src={expandable} alt="" />
//         </button>
//       </div>
//       {section.category.links && section.category.links.length > 0
//         ? section.category.links.map((link) => (
//           // todo: figure out how to trigger each menu individually
//           <li className={`section-links ${expanded ? '' : 'hidden'}`}>
//             <Link to={link.linkUrl}>{link.linkName}</Link>
//           </li>
//         ))
//         : null}
//     </ul>
//   ))
//   : null}
// </FooterUpperWrapper>
const Footer = () => (
  <>
    <BorderLine />
    <FooterBottom>
      <div className="top-row">
        <img src={Logo} alt="logo" />
        <p>
          <a href="/legal/privacy.html" rel="noreferrer" target="_blank">Privacy Policy</a>
          <span> | </span>
          <a href="/legal/privacy.html" rel="noreferrer" target="_blank">Terms of Service</a>
          <span> &copy; </span>
          Copyright 2021. Helvia Technologies IKE
        </p>
      </div>
      {/*<div className="footer-img">*/}
      {/*  <img className="ok-img" src={OKImage} alt="ok_img" />*/}
      {/*</div>*/}
    </FooterBottom>
  </>
)

export default Footer
